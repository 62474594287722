@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.xoclient-usefont {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

html {
    width: 100%;
}

body {
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.xoclient-zoombox {
    transform: scale(1.6);
}

@media only screen and (max-width: 1600px) {
    .xoclient-zoombox {
        transform: scale(1.4);
    }
}

@media only screen and (max-width: 1400px) {
    .xoclient-zoombox {
        transform: scale(1.2);
    }
}

@media only screen and (max-width: 1200px) {
    .xoclient-zoombox {
        transform: scale(1);
    }
}

.xoclient-loadingx1 {
    animation-duration: 1s;
    animation-name: loadingx1;
    animation-iteration-count: infinite;

    position: absolute;
}

@keyframes loadingx1 {
    from {
        left: 0px;
    }

    50% {
        left: 30px;
        transform: rotate(180deg);
    }

    to {
        left: 0px;
        transform: rotate(360deg);
    }
}

.xoclient-loadingx2 {
    animation-duration: 1s;
    animation-name: loadingx2;
    animation-iteration-count: infinite;

    position: absolute;
}

@keyframes loadingx2 {
    from {
        right: 0px;
    }

    50% {
        right: 30px;
        transform: rotate(-180deg);
    }

    to {
        right: 0px;
        transform: rotate(-360deg);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}