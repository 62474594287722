@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.inner {
    font-family: 'Poppins', sans-serif;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 500px;
}

h2 {
    margin-block-end: 0px;
}

p {
    margin-block-start: 0.2em;
}

li {
    display: block;
    margin-left: 1em;
    margin-bottom: 0.5em;
}

a {
    color: rgb(110, 0, 255);
    text-decoration: none;
}