@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
.tictactoe-usefont {
    font-family: 'Baloo 2', sans-serif;
}

html, body {
    height: 100%;
    width: 100%;
}
.xoclient-usefont {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

html {
    width: 100%;
}

body {
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.xoclient-zoombox {
    transform: scale(1.6);
}

@media only screen and (max-width: 1600px) {
    .xoclient-zoombox {
        transform: scale(1.4);
    }
}

@media only screen and (max-width: 1400px) {
    .xoclient-zoombox {
        transform: scale(1.2);
    }
}

@media only screen and (max-width: 1200px) {
    .xoclient-zoombox {
        transform: scale(1);
    }
}

.xoclient-loadingx1 {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-name: loadingx1;
            animation-name: loadingx1;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;

    position: absolute;
}

@-webkit-keyframes loadingx1 {
    from {
        left: 0px;
    }

    50% {
        left: 30px;
        transform: rotate(180deg);
    }

    to {
        left: 0px;
        transform: rotate(360deg);
    }
}

@keyframes loadingx1 {
    from {
        left: 0px;
    }

    50% {
        left: 30px;
        transform: rotate(180deg);
    }

    to {
        left: 0px;
        transform: rotate(360deg);
    }
}

.xoclient-loadingx2 {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-name: loadingx2;
            animation-name: loadingx2;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;

    position: absolute;
}

@-webkit-keyframes loadingx2 {
    from {
        right: 0px;
    }

    50% {
        right: 30px;
        transform: rotate(-180deg);
    }

    to {
        right: 0px;
        transform: rotate(-360deg);
    }
}

@keyframes loadingx2 {
    from {
        right: 0px;
    }

    50% {
        right: 30px;
        transform: rotate(-180deg);
    }

    to {
        right: 0px;
        transform: rotate(-360deg);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.inner {
    font-family: 'Poppins', sans-serif;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 500px;
}

h2 {
    -webkit-margin-after: 0px;
            margin-block-end: 0px;
}

p {
    -webkit-margin-before: 0.2em;
            margin-block-start: 0.2em;
}

li {
    display: block;
    margin-left: 1em;
    margin-bottom: 0.5em;
}

a {
    color: rgb(110, 0, 255);
    text-decoration: none;
}
